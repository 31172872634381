<template>
  <div>
    <CRow align-horizontal="between" align-vertical="center" class="mb-2">
      <CCol>
        <span>The following companies have price updating enabled</span>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <RDataTable
          :items="tableData"
          :fields="columns"
        >
          <template #name="{ item }">
            <td>
              <CLink :to="getLocation(item)">
                {{ item.name }}
              </CLink>
            </td>
          </template>
        </RDataTable>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { formatISO } from 'date-fns'

import { RDataTable } from '@/components/tables'
import { ReportingAPI } from '@/services/api/resources'

const columns = [
  {
    key: 'name',
    label: 'Company Name',
    _style: 'width: 20%'
  },
  {
    key: 'total',
    label: 'Total Tasks',
    _style: 'width: 10%'
  },
  {
    key: 'failed',
    label: 'Failed Tasks',
    _style: 'width: 10%'
  },
  {
    key: 'cancelled',
    label: 'Cancelled Tasks',
    _style: 'width: 10%'
  },
  {
    key: 'expired',
    label: 'Expired Tasks',
    _style: 'width: 10%'
  },
  {
    key: 'successful',
    label: 'Successful Tasks',
    _style: 'width: 10%'
  },
  {
    key: 'active',
    label: 'Active Tasks',
    _style: 'width: 10%'
  }
]
export default {
  name: 'CompanyList',
  components: {
    RDataTable
  },
  props: {
    dateRange: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      tableData: []
    }
  },
  watch: {
    dateRange: {
      handler: function () {
        this.fetchCompanyOverviewData()
      },
      immediate: true
    }
  },
  created () {
    this.columns = columns
  },
  methods: {
    /**
     * Fetch list of companies which are using the Price Updating feature
     */
    async fetchCompanyOverviewData () {
      this.$emit('loading', true)
      const params = new URLSearchParams()
      params.append('from_date', formatISO(this.dateRange.start))
      params.append('to_date', formatISO(this.dateRange.end))
      params.append('tz', this.dateRange.tz)

      const response = await ReportingAPI.priceUpdating.overview({ params })
      if (response) {
        this.tableData = response.data
      }
      this.$emit('loading', false)
    },
    /**
     * Generate the route location object for navigating to
     * the company property list view.
     *
     * @param {Object} row - table row item
     * @returns {Object}
     */
    getLocation (row) {
      return {
        name: 'PriceUpdatingPropertyList',
        params: { cid: row.id }
      }
    }
  }
}
</script>
